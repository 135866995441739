* {
  margin: auto;
  font-family: Georgia, serif;
}

div {
  border-radius: 5px;
}

.header {
  height: 4vh;
  width: 100%;
  background-color: #5f2828;
  position: static;
  z-index: 1;
}

.left {
  height: 100vmax;
  width: 2.5%;
  background-color: #333333;
  float: left;
  position: static;
}

.resume-content {
  display: inline-block;
  margin-top: 50px;
  margin-left: 55px;
  width: 75%;
  height: 100%;
}

.right {
  height: 100vmax;
  width: 2.5%;
  background-color: #333333;
  float: right;
  position: inherit;
}

.footer {
  height: 4vh;
  width: 100%;
  background-color: #5f2828;
  clear: both;
  position: static;
  margin-bottom: 0;
  padding-bottom: 0;
}

#title {
  margin-left: 3%;
}

.head {
  font-size: 20px;
}

#name {
  float: right;
  margin-top: 10px;
  margin-right: 4%;
}

a {
  color: black;
  text-decoration: none;
}

h2 {
  text-decoration: underline;
}

@media only screen and (max-width: 1500px) {
  #name {
    font-size: 13px;
  }
}


@media only screen and (max-width: 1300px) {
  .left,
  .right {
    display: none;
  }
  #footer {
    margin:auto;
  }
  #name {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  #name {
    display: none;
  }
}

@media only screen and (max-width: 430px) {
  .header,
  .left,
  .right,
  .footer,
  #name {
    display: none;
  }
  .resume-content {
    width: 100%;
    margin-left: 10px;
  }
}